<template>
  <div class="page-home">
    <div class="home-slider">
      <el-carousel height="985px">
        <el-carousel-item v-for="item in imglist" :key="item">
          <img :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="home-des" id="home-des"></div>
    <div class="home-des1" id="home-des1"></div>
    <div class="home-industry" id="home-industry">
        <div class="home-industry-title">
            行业背景<span>数字化改革是实现共同富裕的重要手段</span>
        </div>
        <div class="home-industry-body">
            <div class="home-industry-body-left">
                <img src="./../../assets/home/home-industry-body-1.png" alt="">
            </div>
            <div class="home-industry-body-right">
                <div>《中共中央 国务院关于支持浙江高质量<br/>发展建设共同富裕示范区的意见》</div>
                <p>2021年6月10日 新华社发布</p>
            </div>
        </div>
        <div class="home-industry-body">
            <div class="home-industry-body-left">
                <img src="./../../assets/home/home-industry-body-2.png" alt="">
            </div>
            <div class="home-industry-body-right">
                <div>浙江省十四届九次会议通过了共同富裕<br/>示范区实施方案</div>
                <p>2021年6月11日</p>
            </div>
        </div>
        <div class="home-industry-body">
            <div class="home-industry-body-left">
                <img src="./../../assets/home/home-industry-body-3.png" alt="">
            </div>
            <div class="home-industry-body-right" style="padding-top:51px;">
                <div>“共同富裕主战场在'三农'，<br/>短板弱项在'三农'，品牌弱项在'三农’。”</div>
                <p style="margin-left:8%;">浙江省农业农村厅厅长<br/>在共同富裕百村联盟成立大会上的讲话</p>
            </div>
        </div>
        <div class="home-industry-body home-industry-bottom">
            2021年5月20日中共中央国务院下发的《关于支持浙江高质量发展建设共同富裕示范区的意见》中明确指出“完善创新要素与分配机制，支持浙江加快探索知识、技术、管理、数据等要素价值的实现形式”。“立足当地特色资源推动乡村产业发展壮大，完善利益联结机制，让农民更多分享展业增值收益。”
        </div>
    </div>
    <div class="home-location" id="home-location">
        <div class="home-location-title">定位与使命</div>
        <div class="home-location-info">飞村认为，制约现代农村发展的核心在于资本化发展的主张使得社会缺少了对农村发展的准确认知，也同时难以形成为农村建设发声的规模人群，进而导致农村生产力解放这一使命性课题得不到来自社会内部的自发性关注。
也因此，飞村希望借助数字技术与手段打破“农村=农业”这一片面的社会认知，为农村发展提供更广袤的发展空间。</div>
        <div class="home-location-table">
          <div class="home-location-table-item" style="margin-right:3px;">
            <div>飞村使命</div> <p>致力于用数字化手段赋能集体增收和共同致富</p>
        </div>
        <div class="home-location-table-item">
            <div>飞村定位</div> <p>共同富裕服务商，协助提升村组织综合经营<br/>服务能力。</p>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      imglist: [
        require('./../../assets/home/banner1.png'),
        require('./../../assets/home/banner3.png')
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.page-home {
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }

  .home-des{
      display: flex;
      height: 984px;
      background-image: url('./../../assets/home/home-des-1.jpg');
      background-size: cover;
  }
  .home-industry{
      height: 1608px;
      padding: 0 14.6%;
      box-sizing: border-box;
      &-title{
        height: 231px;
        font-size: 52px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #121212;
        line-height: 231px;
        span{
            margin-left: 33px;
            font-size: 36px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #666666;
        }
      }
      &-body{
        display: flex;
        height: 304px;
        margin-bottom: 10px;
        background-color: #006BFF;
        &-left{
            img{
                height: 304px;
            }
        }
        &-right{
          padding-top: 92px;
          padding-left: 4%;
          box-sizing: border-box;
          div{
            font-size: 36px;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 38px;
          }
          p{
            font-size: 28px;
            font-family: Source Han Sans SC;
            color: #121212;
            margin: 0;
          }
        }
      }
      &-bottom{
        padding:69px 6% 0 6%;
        font-size: 28px;
        font-family: Source Han Sans SC;
        color: #FFFFFF;
        line-height: 52px;
      }
  }
  .home-location{
    height: 985px;
    background-image: url('./../../assets/home/home-location.png');
    background-size: cover;
    padding: 101px 14.5% 0 14.5%;
    box-sizing: border-box;
    &-title{
      font-size: 52px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #121212;
      margin-bottom: 64px;
      // background-color: black;
    }
    &-info{
      font-size: 28px;
      font-family: Source Han Sans SC;
      color: #666666;
      line-height: 52px;
      margin-bottom: 66px;
    }
    &-table{
      display: flex;
      &-item{
        flex: 1;
        height: 273px;
        box-sizing: border-box;
        padding-top: 64px;
        padding-left:3%;
        background-color: white;
        div{
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #121212;
          margin-bottom: 34px;
        }
        p{
          font-size: 28px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #121212;
          line-height: 52px;
        }
      }
    }
  }

}

</style>
